import React from 'react'

// import './style.scss';

const Container = ({children}) => (
  <div className="container">
    {children}
  </div>
)

export default Container
